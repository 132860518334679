/*
    Settings: GLOBAL

    These are the base settings used globally across the site.
*/

// Fonts
$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Open Sans', sans-serif;
$font-headings: $font-secondary;

// Sizing and Spacing
$base-font-size: 1.6rem;
$base-line-height: 1.5;

$base-radius: 3px;
$base-gutter: 1rem;
$content-gutter: 1rem;
$button-margin: 1rem 0 1rem 0.8rem; // the last value should be 0.2rem less than the first to account for the HTML spaces between buttons

$base-spacing-unit: 1rem;

// Widths
$width-sm-mobile: 321px;
$width-md-mobile: 376px;
$width-lg-mobile: 426px;
$width-tablet: 769px;
$width-desktop: 1025px;
$width-med-desktop: 1281px;
$width-lg-desktop: 1441px;

$max-width-window: 1366px;
