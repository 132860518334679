/*
* Built by Rocktime Ltd - @rocktime
* 
* Normalize.css is a project by Nicolas Gallagher - @necolas
* ITCSS architecture by Harry Roberts - @csswizardry
* BEM should be used to name classes - http://getbem.com/
*
* * * House Rules * * * * *
*
*   - If in doubt, create a component.
*   - Only use classes.
*   - Nest only to a maximum of 3 layers.
*   - Only use '!important' within the Trump Layer.
*
* * * * * * * * * * * * * *
*/
/*#region SETTINGS */
/*
    Settings: GLOBAL

    These are the base settings used globally across the site.
*/
/*
    Settings: COLORS

    The color palette is where colors across the site are set.
    Here are the rules:
        -   Only reference specific hexadecimal colors within the Color Palette area of this partial.
        -   Color names are based on hexadecimal color here: http://chir.ag/projects/name-that-color/
        -   These variables will be used in various places
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800");
.bold {
  font-weight: 600;
}

/*
    Settings: EXTENDABLES

    These are placeholder selectors which can be extended.
*/
/*#endregion*/
/*#region TOOLS */
/*
    Tools: MIXINS

    SCSS mixins. It is good practice to show how each mixin should be called and the CSS it will output.

Max-width media query

div {
	@include max(768px) {
		display: none;
	}
}

Output:
@media (max-width: 768px) {
	div {
		display: none;
	}
}

*/
/*
    div {
	@include min(768px) {
		display: block;
	}
}

Output:
@media (min-width: 768px) {
	div {
		display: block;
	}
}

*/
/*
Font size rem to px fallback for IE8

p {
    @include font-size(16px); // can be 16 or 16px
}

Output:
p {
    font-size: 16px;
    font-size: 1.6rem;
}
*/
/* Column Percentage Width calculation
    
    Summary: Set a default maxColCount and automatically count the percentage for the number of columns provided

    SCSS example:

    First set your global col variable - $maxColCount: 12;

    .item {
        @include colNum(3)
    }

    Output:

    col-item{
        width: 25%;
    }
*/
/*#endregion*/
/*#region GENERIC */
/*#endregion*/
/*#region ELEMENTS */
/*#endregion*/
/*#region OBJECTS */
/*#endregion*/
/*#region COMPONENTS */
.CheckBoxEx_wrapper {
  margin: 1px;
  display: inline-block;
  padding: 2px 0;
}

.rtHelpMessage {
  display: none;
  color: #000000;
  padding: 1rem 0;
}

.rtHelpMessage a {
  color: #000000 !important;
}

.rtHelpButton_Common {
  display: block;
  overflow: hidden;
  background: url("/resource/help.svg");
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  float: right;
  margin: 0;
  height: 24px;
  width: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.rtTACView, .rtRECView {
  min-height: 21px;
}

.rtTACView {
  font-size: 1.4rem;
}

.rtRECView iframe {
  width: 100%;
}

.rtTAC, .rtREC {
  border: 0 none;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
}

.rtTACEdit, .rtRECEdit {
  clear: both;
}

.rtTACView, .rtRECView iframe {
  background-color: #ffffff;
  border: 2px solid #E0E8EC;
  max-height: 200px;
  overflow-x: auto;
  min-height: 34px;
  border-radius: 4px;
  transition: 0.3s;
  margin: 0;
}

.rtTACView:hover, .rtRECView iframe:hover {
  border-color: #BEBEBE;
}

.rtTACView, .rtTACEdit .riTextBox {
  padding: 8px 10px 8px 33px;
}

@media only screen and (min-width: 768px) {
  .rtTACView, .rtTACEdit .riTextBox {
    padding: 6px 8px;
  }
}

.rtTACEditbtn, .rtRECEditbtn {
  background: url("/resource/edit.svg") center center no-repeat;
  background-size: 18px;
  cursor: pointer;
  position: absolute;
  left: 8px;
  top: 0;
  height: 38px;
  width: 20px;
}

@media only screen and (min-width: 768px) {
  .rtTACEditbtn, .rtRECEditbtn {
    left: -24px;
  }
}

.rtTACEditbtn:hover, .rtRECEditbtn:hover {
  background-image: url("/resource/edit-hover.svg");
}

.rtTACViewbtn, .rtRECViewbtn {
  display: none;
  background: url("/resource/search.svg") center center no-repeat;
  background-size: 18px;
  cursor: pointer;
  position: absolute;
  left: 8px;
  top: 0;
  height: 38px;
  width: 20px;
}

@media only screen and (min-width: 768px) {
  .rtTACViewbtn, .rtRECViewbtn {
    left: -24px;
  }
}

.rtTACViewbtn:hover, .rtRECViewbtn:hover {
  background-image: url("/resource/search-hover.svg");
}

.rt-postback-content-overlay {
  display: none;
}

.rt-postback-content-overlay-message {
  display: none;
}

.postback-lock .rt-postback-content-overlay {
  background-color: rgba(149, 149, 149, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.postback-lock .rt-postback-content-overlay-message {
  background-color: white;
  width: 300px;
  height: 125px;
  margin: auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #939191;
}

.c-error-summary__title {
  font-size: 20px;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: red;
}

.c-error-summary__list li {
  color: red;
}

#buttons_ValidationSummary > div > ul {
  padding-top: 6px;
}

.error-componant {
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 12px;
  width: 100%;
  text-align: left;
  padding: 8px;
  font-size: 14px;
  font-size: 1.4rem;
}

.error-componant__image {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: top;
}

.error-componant__text {
  display: inline-block;
  vertical-align: middle;
}

.error-componant ul {
  list-style: disc;
  margin-left: 2rem;
  margin-bottom: 0;
}

.error-componant__none {
  background: #f8f8f8;
  border: 2px solid #a4a4a4;
  color: #1b1d1e;
}

.error-componant__none .image {
  width: 0;
  height: 0;
}

.error-componant__information {
  border: 2px solid #0000f7;
  background-color: rgba(0, 0, 255, 0.06);
  color: #0000f7;
}

.error-componant__information .image {
  background-image: url('/resource/icon-information.svg"');
}

.error-componant__information .error-componant__text {
  width: calc(100% - 40px);
}

.error-componant__warning {
  border: 2px solid #fb9700;
  background-color: rgba(255, 255, 0, 0.06);
  color: #a56a12;
}

.error-componant__warning .image {
  background-image: url('/resource/icon-warning.svg"');
  height: 27px;
}

.error-componant__warning .error-componant__text {
  width: calc(100% - 40px);
}

.error-componant__critical {
  border: 2px solid #d10000;
  color: #d10000;
  background-color: rgba(255, 0, 0, 0.06);
}

.error-componant__critical .image {
  background-image: url('/resource/icon-critical.svg"');
}

.error-componant__critical .error-componant__text {
  width: calc(100% - 40px);
}

.main-content-body-error {
  font-size: 13px;
  width: 100%;
  display: inline-block;
  padding: 8px 14px;
  margin-top: 20px;
  border-radius: 5px;
}

.main-content-body-error.critical {
  background-color: #fdf2f2;
  border: 2px solid #e77878;
  color: #D20001;
}

.main-content-body-error.information {
  border: 2px solid #0000f7;
  background-color: rgba(0, 0, 255, 0.06);
  color: #1b1d1e;
}

.main-content-body-error.warning {
  border: 2px solid #e9e900;
  background-color: rgba(255, 255, 0, 0.06);
  color: #1b1d1e;
}

.main-content-body-error__image {
  display: inline-block;
  max-width: 46px;
  width: 100%;
  vertical-align: middle;
}

.main-content-body-error__text {
  display: inline-block;
  width: calc(100% - 60px);
  vertical-align: middle;
  padding: 9px 0 9px 10px;
}

.main-content-body-error__title {
  font-size: 16px;
}

.main-content-body-error__attempt {
  float: right;
}

.main-content-body-error__desc {
  width: 100%;
  display: inline-block;
  font-style: italic;
  padding-top: 5px;
}

.day-error {
  border: 1px solid red;
}

.more-text,
.more-text span {
  display: inline-block;
  padding: 0;
  vertical-align: top;
}

.more-text .more-text__max {
  display: none;
}

.more-text .hidden {
  display: none;
}

.more-text .show {
  display: inline-block;
}

.more-text a {
  color: #0064D6;
}

.more-text a:hover {
  color: #0064D6;
  text-decoration: underline;
}

.rtUIWarning {
  padding: 9px 6px;
  font-size: 19px;
  display: none;
  position: fixed;
  top: 0px;
  background-color: #ec4b2e;
  width: 100%;
  color: #FFFFFF;
  text-align: center;
  font-weight: bold;
  z-index: 9999;
}

@media (min-width: 769px) {
  .rtUIWarning {
    padding: 11px 6px;
    font-size: 19px;
  }
}

.rtUIDialog {
  width: 400px;
  text-align: center;
  font-size: 19px;
}

.rtSessionLink {
  border-bottom: 2px solid #ffffff;
  color: #ffffff;
}

.rtSessionLink:hover {
  border-bottom: 0;
}

.rtSessionLink:visited, .rtSessionLink:hover, .rtSessionLink:active {
  text-decoration: none;
}

.rtSessionButton {
  color: #000000;
  font: 12px Verdana, Arial, Helvetica, sans-serif;
  color: #000000;
  border-radius: 5px;
  border: 1px solid #000000;
  height: auto;
  width: auto;
  vertical-align: middle;
  padding: 2px 7px;
  margin: 2px 4px;
  background-color: #e0e0e0;
}

.rtSessionButton:link, .rtSessionButton:visited, .rtSessionButton:hover, .rtSessionButton:active {
  text-decoration: none;
}

.rtSecurityTokenMessage {
  padding: 0 20px;
  width: 400px;
  font-size: 19px;
}

.rtConcurrentLoginMessage {
  position: fixed;
  top: 0px;
  background-color: #ec4b2e;
  width: 100%;
  color: #FFFFFF;
  text-align: center;
  padding: 2px 0px 0px 5px;
  font-size: 19px;
  z-index: 9999;
}

.skip-links {
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: visible;
  width: 100%;
  z-index: 9999;
  margin: 0 !important;
  padding: 0;
}

.skip-links__list-item {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.skip-links__item {
  display: inline-block;
}

.skip-links__item:not(:focus):not(:active) {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.c-file-upload {
  border: 2px solid #E0E8EC;
  padding: 0 8px;
  border-radius: 4px;
  transition: border-color 0.3s;
  font-size: 14px;
}

.c-file-upload:hover {
  border-color: #BEBEBE;
}

.c-file-upload .button:focus {
  outline: 1px solid #000000;
}

.c-file-upload__file-opts {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.6rem;
  font-size: 14px;
}

.c-file-upload__file-opts--hidden {
  display: none;
}

.c-file-upload__file-details {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.6rem;
  font-size: 14px;
}

.c-file-upload__file-details--hidden {
  display: none;
}

.c-file-upload__file-details:hover {
  border-color: #BEBEBE;
}

.c-file-upload__delete, .c-file-upload__replace {
  margin-bottom: 1rem;
}

.c-file-upload__file-cta {
  /*display: flex;*/
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  padding-left: 1rem;
  font-size: 16px;
  text-align: right;
}

.c-file-upload__file-cta .button {
  display: inline-block;
}

.c-file-upload__file-cta .button:first-child {
  margin: 0.6rem 0 0 0.6rem;
}

.c-file-upload__file-cta .button:not(:first-child) {
  margin: 0.6rem 0 0 0.6rem;
}

.c-file-upload__file-cta label {
  white-space: nowrap;
}

.c-file-upload__info {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  margin-top: 0.6rem;
}

.c-file-upload__file-text {
  display: inline-block;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.c-file-upload__opt-ctas {
  /*display: flex;*/
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  text-align: right;
}

.c-file-upload__opt-ctas .button {
  display: inline-block;
}

.c-file-upload__opt-ctas .button:first-child {
  margin: 0.6rem 0 0 0.6rem;
}

.c-file-upload__opt-ctas .button:not(:first-child) {
  margin: 0.6rem 0 0 0.6rem;
}

.c-file-upload__view-image {
  min-width: 35px;
  margin-right: 1rem;
  word-break: break-word;
}

.c-file-upload__view-file {
  min-width: 35px;
  margin-right: 1rem;
  word-break: break-word;
}

.c-file-upload__view-file img {
  min-height: 35px;
}

.c-file-upload__upload-msg {
  margin: 0.6rem 0 0 0;
}

.c-file-upload__upload-box:hover {
  cursor: pointer;
}

.c-file-upload__input:focus + .c-file-upload__upload-msg + .c-file-upload__file-cta > .c-file-upload__upload-box {
  outline: 1px solid #000000;
}

.c-file-upload input {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  overflow: hidden !important;
}

.c-file-upload input:focus + div > label {
  outline: 1px solid #000000;
}

.c-file-upload-container--with-upload {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  border: 2px solid #E0E8EC;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.c-file-upload-container--with-upload:hover {
  border-color: #BEBEBE;
}

.c-file-upload-container--with-upload .c-file-upload {
  width: 100%;
  border: 0;
}

.c-file-upload-container--with-upload > input {
  margin: 0.6rem 0;
}

.pass_help span, .pass_help ul, .pass_help li, .pass_help span, .pass_help ul, .pass_help li {
  font-size: 1.2rem;
  color: red;
}

.pass_help span, .pass_help ul, .pass_help span, .pass_help ul {
  margin-left: 5px;
}

.pass_help ul, .pass_help ul {
  list-style: disc;
}

.pass_help li, .pass_help li {
  margin-left: 22px;
}

.pass_score {
  text-align: center;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  border-width: 1px;
  border-style: solid;
  margin: 2px 1px;
  border-color: #000;
  padding: 2px 2px 3px;
}

.RocktimeFieldErrorGroup, .RocktimeFieldErrorMarkerBorder, .RocktimeFieldErrorCommon, .RocktimeFieldErrorRightPadding, .RocktimeFieldErrorRightPadding2, .RocktimeFieldErrorWrapper, .RocktimeFieldErrorCheckBox label::before,
.RocktimeFieldErrorCheckBox .switch-toggle, .RocktimeFieldErrorMarker .rlbGroup, .RocktimeFieldErrorMarker .riEnabled, .RocktimeFieldErrorMarker .riHover, .RocktimeFieldErrorMarker iframe, .RadComboBox.RocktimeFieldErrorMarker > table,
.RocktimeFieldErrorMarker.riSingle > input, .RocktimeFieldErrorRadTextBoxMultiLine.rtTACView, .RocktimeFieldErrorRadTextBoxMultiLine textarea {
  border-radius: 0;
  border: 2px solid #FF0000;
  border-color: #FF0000 !important;
}

.RocktimeFieldErrorGroup .rcbInner input, .RocktimeFieldErrorMarkerBorder .rcbInner input, .RocktimeFieldErrorCommon .rcbInner input, .RocktimeFieldErrorRightPadding .rcbInner input, .RocktimeFieldErrorRightPadding2 .rcbInner input, .RocktimeFieldErrorWrapper .rcbInner input, .RocktimeFieldErrorCheckBox label::before .rcbInner input,
.RocktimeFieldErrorCheckBox .switch-toggle .rcbInner input, .RocktimeFieldErrorMarker .rlbGroup .rcbInner input, .RocktimeFieldErrorMarker .riEnabled .rcbInner input, .RocktimeFieldErrorMarker .riHover .rcbInner input, .RocktimeFieldErrorMarker iframe .rcbInner input, .RadComboBox.RocktimeFieldErrorMarker > table .rcbInner input,
.RocktimeFieldErrorMarker.riSingle > input .rcbInner input, .RocktimeFieldErrorRadTextBoxMultiLine.rtTACView .rcbInner input, .RocktimeFieldErrorRadTextBoxMultiLine textarea .rcbInner input {
  border: 0 !important;
}

.RocktimeFieldErrorCommon, .RocktimeFieldErrorRightPadding, .RocktimeFieldErrorRightPadding2, .RocktimeFieldErrorWrapper, .RocktimeFieldErrorMarker .rlbGroup,
.RocktimeFieldErrorMarker .riEnabled, .RocktimeFieldErrorMarker .riHover, .RocktimeFieldErrorMarkerPadding .riEnabled, .RocktimeFieldErrorMarkerPadding .riHover,
.RocktimeFieldErrorMarkerPadding iframe, .RocktimeFieldErrorMarker.riSingle > input, .RocktimeFieldErrorRadTextBoxMultiLine {
  background-color: #FFFFFF !important;
}

.checklist .RocktimeFieldErrorWrapper {
  background-color: transparent !important;
}

.RocktimeFieldErrorWrapper {
  display: block;
  margin-left: -5.5px;
  margin-top: -5.5px;
  width: 100%;
  padding: 4px;
}

.RocktimeFieldErrorWrapper + span {
  margin-top: 10px;
}

.RocktimeFieldErrorGroup span.RadButton {
  border: none;
}

.RocktimeFieldErrorCheckBox {
  background-color: transparent !important;
  margin: 0;
  display: table;
  min-height: 25px;
}

.RocktimeFieldErrorCheckBox .switch-toggle {
  margin: -2px 8px 0px -4px;
  padding: 2px 0 0 2px;
  width: 100%;
}

.rlbItem.rlbSelected {
  background-color: rgba(197, 197, 197, 0.3) !important;
}

.RocktimeFieldErrorDate .date-combobox-child .RadComboBox, .RocktimeFieldErrorDate .date-droplist-child .RadDropDownList, .RocktimeFieldErrorDate .date-droplist-child select {
  border: 2px solid #FF0000;
}

.RadEditor.RocktimeFieldErrorMarker .reLayoutWrapper iframe {
  border-radius: 4px;
  border: 2px solid #FF0000;
}

.c-popout-message-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  z-index: 9989;
}

.c-popout-message-panel__view {
  position: relative;
  width: 100%;
  z-index: 9987;
  overflow: auto;
}

.c-popout-message-panel__view--hidden {
  max-height: 0;
  overflow: hidden;
}

.c-popout-message-panel__view--hidden .c-popout-message-panel__view-close {
  display: none;
}

.c-popout-message-panel__view-close {
  position: fixed;
  top: 10px;
  right: 15px;
  text-align: left;
  width: 60px;
  height: 25px;
  z-index: 9988;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
}

.c-popout-message-panel__view-close:before, .c-popout-message-panel__view-close:after {
  content: '';
  position: absolute;
  top: 9px;
  right: 0;
  background-color: #1b1d1e;
  width: 20px;
  height: 6px;
  border-radius: 2px;
}

.c-popout-message-panel__view-close:before {
  transform: rotate(-45deg);
}

.c-popout-message-panel__view-close:after {
  transform: rotate(45deg);
}

.c-popout-message-panel__content {
  position: relative;
  background-color: #ffffff;
  padding: 10px;
  border-bottom: 1px solid #aaaaaa;
  overflow: auto;
}

.c-popout-message-panel__ctrl {
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 9986;
}

.c-popout-message-panel__ctrl-button {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  /*background-color: $color-white;*/
  background: linear-gradient(to bottom, #dbdbdb 0%, white 100%);
  width: 120px;
  height: 7px;
  text-indent: -9999px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  box-shadow: 0 0 3px rgba(27, 29, 30, 0.8);
  transition: all 0.2s ease-in-out;
  opacity: 0.5;
}

.c-popout-message-panel__ctrl-button:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 50%;
  margin-left: -21px;
  width: 42px;
  height: 4px;
  background-color: #eeeeee;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(27, 29, 30, 0.4);
  opacity: 0;
}

.c-popout-message-panel__ctrl-button:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 4px;
  background-color: #eeeeee;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 3px rgba(27, 29, 30, 0.4);
  border-radius: 5px;
  opacity: 0;
}

.c-popout-message-panel__ctrl-button:hover {
  height: 30px;
  opacity: 1;
}

.c-popout-message-panel__ctrl-button:hover:before {
  top: 10px;
  opacity: 1;
}

.c-popout-message-panel__ctrl-button:hover:after {
  top: 18px;
  opacity: 1;
}

.c-title-ctrls {
  display: inline;
  padding: 0 1rem;
}

.c-title-ctrls ul {
  display: inline;
  width: auto;
  margin-bottom: 0;
}

.c-title-ctrls li {
  padding: 0;
  margin: 0;
}

.c-title-ctrls__ctrl {
  display: inline-block;
}

.c-title-ctrls svg {
  width: 100%;
}

/*#endregion*/
/*#region TRUMPS */
/*#endregion*/
