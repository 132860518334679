/*
* Built by Rocktime Ltd - @rocktime
* 
* Normalize.css is a project by Nicolas Gallagher - @necolas
* ITCSS architecture by Harry Roberts - @csswizardry
* BEM should be used to name classes - http://getbem.com/
*
* * * House Rules * * * * *
*
*   - If in doubt, create a component.
*   - Only use classes.
*   - Nest only to a maximum of 3 layers.
*   - Only use '!important' within the Trump Layer.
*
* * * * * * * * * * * * * *
*/

/*#region SETTINGS */
// Variables and base site settings used globally only.
@import "../../shared/settings/global";
@import "../../shared/settings/colors";
@import "../../shared/settings/fonts";
@import "../../shared/settings/weights";
@import "../../shared/settings/extendables";
/*#endregion*/

/*#region TOOLS */
// Mixins and functions which are used globally.
@import "../../shared/tools/functions";
@import "../../shared/tools/mixins";
/*#endregion*/

/*#region GENERIC */
// General base styling. Not normally changed.
/*#endregion*/

/*#region ELEMENTS */
// Bare unclassed elements. No classes used.
/*#endregion*/

/*#region OBJECTS */
// Non-cosmetic design patterns. Classes used.
/*#endregion*/

/*#region COMPONENTS */
// Pieces of user interface. Cosmetic design patterns.
@import "components/core-common/cpcontrol-wrappers";
@import "components/core-common/help";
@import "components/core-common/text-editor-area-preview";
@import "components/core-common/postback-loading-panel";
@import "components/error-messages/error-summary";
@import "components/error-messages/errors";
@import "components/more-text/more-text";
@import "components/session-message/session-message";
@import "components/skip-links/skip-links";
@import "components/upload/file-upload";
@import "components/validation/password";
@import "components/validation/validation";
@import "components/popout-message-panel/popout-message-panel";
@import "components/title-controls/title-controls";
/*#endregion*/

/*#region TRUMPS */
// Overrides and heavy-handed styling.
/*#endregion*/
